html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video {
  background-color: #000;
  /* object-fit: cover; */
}

textarea {
  border: 2px solid #a18eee;
  color: #bdbdbd;
  border-radius: 0;
  font-size: 32px;
  line-height: 40px;
}

textarea:hover,
textarea:focus {
  border: 2px solid #a18eee;
  border-radius: 0;
  outline: 0 !important;
}

/*****************************************************************************/
/* HELPERS/UTILS                                                             */
/*****************************************************************************/

@media (min-width: 1366px) {
  .d-hd-none {
    display: none !important;
  }

  .d-hd-block {
    display: block !important;
  }
}

.vh-100 {
  height: 100vh;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-600 {
  font-weight: 600;
}

.bg-cream {
  background-color: #f5f2e9;
}

.bg-dark-purple {
  background-color: #171033;
}

.text-dark-purple {
  color: #171033;
}

.bg-semi-transparent-white {
  background-color: rgba(255, 255, 255, 0.7);
}

.rotate-180 {
  transform: rotate(180deg);
}

.bb-dotted-1 {
  border-bottom: 1px #ffffff dotted;
}

.bg-radial-gradient {
  background: radial-gradient(
    89.5% 89.5% at 50% 50%,
    rgba(255, 255, 255, 0.8) 38.5%,
    rgba(230, 232, 233, 0.8) 73.17%,
    #d6d9d8 100%
  );
}

.btn {
  border-radius: 0;
  padding: 7px 14px 8px;
  font-weight: 600;
  font-size: 20px;
}

.btn-default {
  background-color: transparent;
  border: 1px solid #171033;
  box-sizing: border-box;
  color: #171033;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.btn-default-no-border {
  background-color: transparent;
  border: 0px solid #171033;
  box-sizing: border-box;
  color: #171033;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.btn-outline-white {
  background-color: transparent;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  color: #ffffff;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.btn-outline-white:hover {
  color: #ffffff;
}

.btn-outline-black {
  background-color: transparent;
  border: 1px solid #000;
  box-sizing: border-box;
  color: #000;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.btn-outline-black:hover {
  color: #000;
}

.btn-success {
  background-color: #00cf08;
  border: 1px solid #fbfaf6;
  box-sizing: border-box;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-danger {
  background-color: #ec2a00;
  border: 1px solid #fbfaf6;
  box-sizing: border-box;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.title,
.prompt-modal__title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
}

/*****************************************************************************/
/* VENDOR OVERWRITES                                                         */
/*****************************************************************************/

.ReactModal__Overlay {
  background-color: rgba(23, 16, 51, 0.6) !important;
}

/*****************************************************************************/
/* LANGUAGE SELECTOR                                                         */
/*****************************************************************************/

.language-selector {
  right: 1rem;
  top: 1rem;
  position: absolute;
  color: #ffffff;
  font-size: 22px;
  transition: all 0.3s;
}

.language-selector:hover {
  color: #ffffff;
}

.language-selector--dark {
  color: #171033;
}

.language-selector--dark:hover {
  color: #171033;
  text-decoration: underline;
}

/*****************************************************************************/
/* HOME PAGE                                                                 */
/*****************************************************************************/

.home__main {
  padding: 2rem 0;
  transition: top 0.9s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.home__main--off-screen {
  top: -1000px;
}

.home__main-card {
  max-width: 616px;
  max-height: 588px;
  position: relative;
  text-align: center;
  padding: 1.5rem;
  margin: 0 auto;
}

.home__main-card-unsupported {
  width: 616.85px;
  height: 320px;
  font-size: 16px;
  line-height: 18px;
  position: relative;
  text-align: center;
  padding: 1.5rem 1.5rem 3rem;
  margin: 0 auto;
}

.home__main-card__title {
  font-size: 22px;
  line-height: 27px;
  font-weight: 600;
}

.home__main-card__text {
  font-size: 16px;
  line-height: 22px;
}

.home__main-card__btn-time {
  background-color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  transition: border-width 0.2s;
  border: 0px solid #a18eee;
  color: #171033;
  padding: 0.25rem 1.25rem;
  margin: 0 0.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.feedback-title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
}

@media (min-width: 769px) {
  /* .home__main-card {
    margin: 0 auto;
  } */

  .home__main-card__title {
    font-size: 32px;
    line-height: 40px;
  }
  .home__main-card__text {
    font-size: 22px;
    line-height: 28px;
  }

  .home__main-card__btn-time {
    width: 160px;
    height: 172px;
    font-size: 42px;
    line-height: 52px;
    padding: 0.5rem;
  }

  .home__main-card__btn-time:hover {
    border-width: 4px;
    text-decoration: none;
    color: #171033;
  }
}

.home__main__logo {
  width: 255px;
}

.home__slide-link {
  font-size: 20px;
  line-height: 28px;
  max-width: 280px;
  text-transform: uppercase;
}

.home__main__logo {
  width: 255px;
}

.home__slide-link {
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
}

.logo-type--small {
  font-size: 46px;
  line-height: 50px;
}

@media (min-width: 769px) {
  .logo-type {
    font-size: 72px;
    line-height: 90px;
  }
}

.viewport-container {
  width: 1440px;
  position: relative;
  height: 900px;
}

.home__team-picture-bubble {
  display: flex;
  justify-content: center;
  max-width: 420px;
  overflow: hidden;
  justify-content: center;
  align-items: start;
  margin: 0 auto;
  transition: top 0.9s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.home__team-picture-bubble--lifted {
  top: 30%;
}

.home__team-picture-bubble__image {
  width: 407px;
  height: 410px;
  background-color: #e17d5e;
  border-radius: 50%;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.home__about {
  transition: top 0.9s cubic-bezier(0.165, 0.84, 0.44, 1);
  max-width: 620px;
  padding: 0 1.5rem;
  margin: 0 auto;
}

.home__about--off-screen {
  top: 100%;
}

/*****************************************************************************/
/* CURTAINS                                                                  */
/*****************************************************************************/

.curtain-container {
  position: absolute;
  transition: all 0.9s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.curtain-container--left {
  top: 0;
  height: 75%;
  left: 0;
  width: 344px;
  overflow: hidden;
}

.curtain-container--left-bottom {
  height: 25%;
  width: 123px;
  bottom: 0;
  top: auto;
}

.curtain-container--right {
  top: 0;
  height: 75%;
  overflow: hidden;
  width: 344px;
  left: auto;
  right: 0;
}

.curtain-container--right-bottom {
  height: 25%;
  bottom: 0;
  top: auto;
  width: 123px;
  left: auto;
  right: 0;
}

.curtain-offscreen {
  width: 100%;
  position: absolute;
  background-color: #171033;
  height: 100%;
}

.curtain-offscreen--left {
  right: 344px;
  top: 0;
}

.curtain-offscreen--left-bottom {
  right: 173px;
  top: 0;
}

.curtain-offscreen--right {
  left: 344px;
  right: auto;
  top: 0;
}

.curtain-offscreen--right-bottom {
  right: auto;
  left: 173px;
  top: 0;
}

.curtain {
  position: absolute;
  height: 100%;
  background-clip: content-box;
}

.curtain--left {
  top: 0;
  width: 358px;
  right: 0;
}

.curtain--left:after {
  content: '';
  position: absolute;
  background-color: #171033;
  width: 100%;
  transform: rotate(0deg) skewX(-24.1deg);
  height: 100%;
  transform-origin: bottom;
  left: -100%;
}

.curtain--left-bottom {
  position: absolute;
  height: 100%;
  right: 0;
  bottom: 0;
  width: 181px;
  top: auto;
}

.curtain--left-bottom:after {
  content: '';
  position: absolute;
  width: 100%;
  background-color: #171033;
  transform-origin: top;
  transform: rotateY(180deg) skewX(-35.4deg);
  height: 100%;
  left: -100%;
}

.curtain--right {
  top: 0;
  left: 0;
  left: auto;
  width: 358px;
}

.curtain--right:after {
  content: '';
  position: absolute;
  background-color: #171033;
  width: 100%;
  transform: rotate(0deg) skewX(24.1deg);
  height: 100%;
  left: auto;
  transform-origin: bottom;
  right: -100%;
}

.curtain--right-bottom {
  position: absolute;
  height: 100%;
  left: 0;
  bottom: 0;
  width: 181px;
  top: auto;
}

.curtain--right-bottom:after {
  content: '';
  position: absolute;
  background-color: #171033;
  width: 100%;
  transform: rotate(0deg) skewX(-35.1deg);
  height: 100%;
  left: auto;
  transform-origin: top;
  right: -100%;
}

.curtain-container--left--default,
.curtain-container--right--default {
  width: 144px;
}

.curtain-container--left--open,
.curtain-container--right--open {
  width: 300px;
}

.curtain-container--bottom--default {
  width: 173px;
}

.curtain-container--left--invisible,
.curtain-container--right--invisible,
.curtain-container--left-bottom--invisible,
.curtain-container--right-bottom--invisible {
  width: 0 !important;
}

.curtain-container--left-bottom--open,
.curtain-container--right-bottom--open {
  width: 150px;
}

.curtain-container--left--closed,
.curtain-container--right--closed,
.curtain-container--left-bottom--closed,
.curtain-container--right-bottom--closed {
  width: 100%;
}

@media (min-width: 769px) {
  .curtain-container--right-bottom {
    width: 173px;
  }

  .curtain-container--left-bottom {
    width: 173px;
  }

  .curtain-container--left--default,
  .curtain-container--right--default {
    width: 344px;
  }

  .curtain-container--left--closed,
  .curtain-container--right--closed,
  .curtain-container--left-bottom--closed,
  .curtain-container--right-bottom--closed {
    width: 100%;
  }
}

/*****************************************************************************/
/* FLOW SELECTION PAGE                                                       */
/*****************************************************************************/

.experience-page-link,
.experience-page-nav-link {
  color: #171033;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-bottom: 2px solid transparent;
}

.experience-page-link:hover,
.experience-page-nav-link:hover,
.experience-page-nav-link.active {
  text-decoration: none;
  color: #171033;
  border-color: #171033;
}

.experience-page-link {
  font-size: 32px;
  font-weight: 600;
}

.experience-page-link-small {
  font-size: 29px;
}

.experience-page-nav-link {
  font-size: 24px;
  font-weight: 600;
}

.prompt-modal__message {
  font-size: 22px;
}

.flow-title-bar {
  top: 0;
  font-size: 4vh;
  line-height: 65px;
  font-weight: 600;
}

.flow-control-bar {
  font-size: 24px;
  font-weight: 600;
  background-color: rgba(23, 16, 51, 0.6);
  padding: 12px 60px;
}

.video-controls__progress-bar {
  background-color: #171033;
  position: relative;
  height: 6px;
}

.video-controls__progress-bar__thumb {
  background-color: #e17d5e;
  height: 100%;
  transform: none !important;
}

.video-controls__btn {
  background: transparent;
  color: #fff;
}

/*****************************************************************************/
/* CREDITS PAGE                                                              */
/*****************************************************************************/

.credits {
  font-size: 20px;
  line-height: 28px;
  max-width: 820px;
}

.credits-roll__container {
  height: 590px;
  overflow: hidden;
  position: relative;
}

.credits-roll__roller {
  animation: creditsRoll 20s linear;
  position: relative;
  bottom: -100%;
}

@keyframes creditsRoll {
  0% {
    bottom: -590px;
  }

  100% {
    bottom: 590px;
  }
}

/*****************************************************************************/
/* FEEDBACK PAGE                                                             */
/*****************************************************************************/

.feedback-drag-placeholder {
  width: 100%;
  height: 100%;
  font-weight: 600;
  text-align: center;
  color: #bdbdbd;
  font-size: 32px;
  line-height: 40px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 70px;
}

.feedback-textarea {
  font-weight: 600;
  color: #171033;
  font-size: 32px;
}

.feedback-textarea::placeholder {
  color: #bdbdbd;
  /* text-align: center; */
}

/*****************************************************************************/
/* WHO SHOULD TELL YOU NEXT STEP                                             */
/*****************************************************************************/

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0;
  opacity: 0;
  transition: all 0.4s;
}

.overlay:hover {
  background: linear-gradient(
    0deg,
    rgba(161, 142, 238, 0.7) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  font-size: 50px;
  color: #ffffff;
  opacity: 1;
}

.overlay__play {
  width: 100px;
  height: 100px;
}

/*****************************************************************************/
/* ENSEMBLE POLL STEP                                                        */
/*****************************************************************************/

.ensemble-poll-option__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  font-size: 0;
  transition: opacity 0.4s;
  font-size: 50px;
  color: #ffffff;
}

.ensemble-poll-option__overlay__box {
  background-color: rgba(23, 16, 51, 0.6);
  cursor: pointer;
}

.ensemble-poll-option__overlay__mark {
  border: 2px solid #fbfaf6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ensemble-poll-option__overlay__mark__fill {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 50%;
  border: 8px #a18eee solid;
}

.ensemble-poll-results__title {
  font-size: 194px;
  color: #171033;
  font-weight: 600;
  line-height: 194px;
  position: absolute;
  top: -42px;
  width: 100%;
  text-align: center;
  text-shadow: 1px 14px 0px rgba(0, 0, 0, 0.2);
}

.ensemble-poll-intro__text {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

/*****************************************************************************/
/* YES NO STEP                                                               */
/*****************************************************************************/

.yes-no-step-bg {
  background: url('/yes-no-step-bg.png') no-repeat bottom center;
}

/*****************************************************************************/
/* WHERE NEXT STEP                                                           */
/*****************************************************************************/

.map-image {
  background: url('/berlin-map.svg');
  width: 1010px;
  height: 643px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.map-pin--museum {
  cursor: pointer;
  position: absolute;
  width: 127px;
  height: 127px;
  left: 267px;
  top: 254px;
}

.map-pin--museum:hover {
  width: 137px;
  height: 137px;
  left: 262px;
  top: 245px;
}

.map-pin--alex {
  cursor: pointer;
  position: absolute;
  width: 127px;
  height: 127px;
  left: 646px;
  top: 158px;
}

.map-pin--alex:hover {
  width: 137px;
  height: 137px;
  left: 641px;
  top: 149px;
}

.map-label {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #000000;
}

.map-label-museum {
  position: relative;
  width: 338px;
  height: 40px;
  left: 170px;
  top: 390px;
}

.map-label-alex {
  position: relative;
  width: 338px;
  height: 40px;
  left: 540px;
  top: 250px;
}

/*****************************************************************************/
/* DRAWING                                                                   */
/*****************************************************************************/

.drawing-placeholder {
  width: 540px;
  height: 240px;
  left: 0px;
  top: 0px;
  border: 1px dashed #bdbdbd;
  font-weight: 600;
  text-align: center;
  color: #bdbdbd;
  font-size: 32px;
  line-height: 40px;
  box-sizing: border-box;
}

.drawing-placeholder--small {
  left: 0px;
  top: 0px;
  font-weight: 600;
  text-align: center;
  color: #bdbdbd;
  font-size: 16px;
  line-height: 20px;
}
.title--small {
  font-size: 16px;
  line-height: 20px;
}

/*****************************************************************************/
/* CHOOSE MUSIC PAGE                                                         */
/*****************************************************************************/

.choose-music__container {
  font-size: 0; /*fix inline gaps*/
  height: 100%;
}
.choose-music__item {
  font-size: 16px; /*reset font size*/
  display: inline-block;
  vertical-align: top;
  width: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  position: relative;
  overflow: auto; /*for scrollbar*/
}

.choose-music__item__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
    0deg,
    rgba(161, 142, 238, 0.7) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  opacity: 0;
  transition: opacity 0.3s;
}

.choose-music__item:hover .choose-music__item__overlay {
  opacity: 1;
}

/*****************************************************************************/
/* INSZENIERTE STADT                                                         */
/*****************************************************************************/

.video-stadt__controls {
  position: absolute;
  width: 100%;
  bottom: 86px;
  text-align: center;
}

.video-stadt__controls__title {
  width: 288px;
  background: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #fbfaf6;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
}

.video-stadt__controls__button {
  font-size: 24px;
  line-height: 30px;
  text-transform: capitalize;
  color: #fbfaf6 !important;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: 1px solid transparent;
  outline: none !important;
}

.video-stadt__controls__button:focus {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.video-stadt__controls__button:not(.active):hover {
  border-color: #fbfaf6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.video-stadt__controls__button.active {
  background-color: #171033;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

/*****************************************************************************/
/* PDF SCORE                                                                 */
/*****************************************************************************/

.pdf-score-instructions {
  width: 283px;
  height: 400px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: 10px solid #fbfaf6;
}

.pdf-score {
  width: 283px;
  height: 400px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: 10px solid #fbfaf6;
}

.pdf-container {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

/*****************************************************************************/
/* ASMR                                                                      */
/*****************************************************************************/

.asmr-placeholder {
  width: 540px;
  height: 360px;
  border: 1px dashed #bdbdbd;
  font-weight: 600;
  text-align: center;
  color: #bdbdbd;
  font-size: 32px;
  line-height: 40px;
}

/*****************************************************************************/
/* Loading Page                                                               */
/*****************************************************************************/

.loading-title {
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
}

.loading-text {
  font-size: 25px;
  line-height: 40px;
  font-weight: 300;
}

.yes-no-video__buttons-container {
  top: 6rem;
}
.ensemble-poll-results__container {
  max-width: 480px;
}

.ensemble-poll-intro__image {
  max-width: 420px;
}

.feedback__title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  margin: 0.5rem 0;
}

.landscape-warning {
  display: none;
}

@media screen and (orientation: portrait) {
  .landscape-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 32px;
    line-height: 40px;
    height: 100vh;
    width: 100vw;
  }
}

.flow-selection-menu__container {
  transition: opacity 0.5s ease 0s;
  top: 25%;
  height: 50%;
}

.experience-page-nav-link__img {
  width: 34px;
}
@media screen and (orientation: landscape) and (max-device-width: 898px) {
  .flow-selection-menu__container {
    width: 270px;
  }

  .experience-page-nav-link__img {
    width: 18px;
  }

  .feedback__title {
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
  }
  .experience-page-nav-link {
    font-size: 12px;
  }
  .experience-page-link-small {
    font-size: 70%;
  }

  .video-controls__title,
  .video-controls__btn {
    font-size: 75%;
  }

  .overlay {
    background: linear-gradient(
      0deg,
      rgba(161, 142, 238, 0.7) 0%,
      rgba(0, 0, 0, 0) 70%
    );
    font-size: 50px;
    color: #ffffff;
    opacity: 1;
    justify-content: end;
    padding-bottom: 6px;
  }

  .overlay__play {
    height: 70px;
    width: 70px;
  }

  .overlay__title {
    font-size: 24px;
    line-height: 30px;
  }

  .yes-no-video__buttons-container {
    top: 3rem;
  }

  .flow-title-bar {
    font-size: 26px;
    line-height: 30px;
  }

  .ensemble-poll-option__overlay__box {
    font-size: 20px;
    line-height: 22px;
    padding: 10px;
  }

  .ensemble-poll-option__overlay__mark {
    width: 25px;
    height: 25px;
  }

  .ensemble-poll-intro__text {
    font-size: 20px;
    line-height: 26px;
  }

  .ensemble-poll-intro__image {
    max-width: 220px;
  }

  .ensemble-poll-results__container {
    max-width: 240px;
  }

  .ensemble-poll-results__title {
    font-size: 84px;
  }

  .feedback-drag-placeholder {
    font-size: 14px;
    line-height: 20px;
  }
}
